import { Flex, MenuItem, Text } from "@chakra-ui/react";
import { CheckMarkIcon, useThemeTokens } from "@design-system";
import { Dispatch, ReactNode } from "react";

type DropdownItemProps<T> = {
  icon?: JSX.Element;
  label?: string;
  content?: ReactNode;
  value: T;
  selectedValue: T;
  setSelectedValue: Dispatch<T>;
};

export function DropdownItem<T>({
  icon,
  value,
  selectedValue,
  setSelectedValue,
  label,
  content,
}: DropdownItemProps<T>) {
  const {
    dropDownItem: {
      selected: {
        bg,
        icon: { color },
      },
    },
  } = useThemeTokens();
  const body = content ?? label ?? (value ? `${value}` : null);
  const selected = value === selectedValue;

  return (
    <MenuItem onClick={() => setSelectedValue(value)} icon={icon} bg={selected ? bg : undefined}>
      <Flex width="full">
        {typeof body === "string" ? <Text>{body}</Text> : body}
        <CheckMarkIcon color={color} width="1rem" height="1rem" opacity={selected ? 1 : 0} marginStart="0.5rem" />
      </Flex>
    </MenuItem>
  );
}
