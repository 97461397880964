import { OrganizationMembership } from "@auth/AuthContext.ts";
import { Box, Button, ButtonGroup, Center, Heading, Link, Text, VStack } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { useCreateProject, useTranslation } from "@hooks";
import { Trans } from "react-i18next";

export function ProjectsEmptyState({
  organization: { organizationId, memberRole },
  ...props
}: { organization: OrganizationMembership } & StyleProps) {
  const { t } = useTranslation("home.projects.emptyState");
  const { createAndNavigateToProject } = useCreateProject();

  return (
    <Center as={VStack} alignContent="center" gap={8} {...props}>
      <VStack>
        <Heading as="h1">{t("title")}</Heading>
        <Text textAlign="center" variant="m-regular">
          <Trans i18nKey="home.projects.emptyState.subtitle" components={{ Link: <Link /> }} />
        </Text>
      </VStack>
      <ButtonGroup>
        {["admin", "member"].indexOf(memberRole) !== -1 && (
          <Button
            variant="primary"
            onClick={() => createAndNavigateToProject({ organizationId })}
            data-test-id="create-project-button"
          >
            {t("actions.create.label")}
          </Button>
        )}
      </ButtonGroup>
      <Box height="8rem" />
    </Center>
  );
}
