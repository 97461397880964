import { ModelAttributes, PathCollectionAttributes } from "@models/backend";

export const modelAttributes: ModelAttributes = {
  stitchDensity: {
    course: 12.0,
    wale: 8.0,
  },
  sections: {
    count: 10,
  },
  columns: {
    count: 10,
  },
  calibrationGrid: {
    include: true,
  },
};

type DefaultPathCollectionAttributes = Omit<PathCollectionAttributes, "transition"> & {
  transition: Required<Required<PathCollectionAttributes>["transition"]>;
};

export const pathCollectionAttributes: DefaultPathCollectionAttributes = {
  stitchDensity: {
    course: 12.0,
    wale: 8.0,
  },
  transition: {
    boundary: 0,
    top: 0,
    bottom: 0,
  },
};
