import { Auth0ContextInterface } from "@auth0/auth0-react";
import { Button, ButtonGroup, Center, Flex, Heading, Spacer } from "@chakra-ui/react";
import { useTranslation } from "@hooks";

export type OnboardingProps = {
  loginWithRedirect: Auth0ContextInterface["loginWithRedirect"];
};

//https://www.figma.com/file/IrSHApht4p7tXsNxouLqAf/LOOP-UI?type=design&node-id=7209-97100&mode=design&t=D0fPkU8x3MMbeDuZ-4
export function Onboarding({ loginWithRedirect }: OnboardingProps) {
  const { t } = useTranslation("onboarding");
  const appState = { returnTo: window.location.href };

  return (
    <Flex padding="6" flexFlow="column" height="full">
      <Flex minWidth="max-content" alignItems="center" gap="2">
        <Spacer />
        <ButtonGroup gap="2">
          <Button
            onClick={() =>
              loginWithRedirect({
                appState,
                authorizationParams: { screen_hint: "login" },
              })
            }
            variant="outline"
            data-test-id="login-button"
          >
            {t("actions.login.label")}
          </Button>
          <Button
            onClick={() =>
              loginWithRedirect({
                appState,
                authorizationParams: { screen_hint: "signup" },
              })
            }
            variant="primary"
            width="48"
            data-test-id="signup-button"
          >
            {t("actions.register.label")}
          </Button>
        </ButtonGroup>
      </Flex>
      <Center flex="1 1 auto">
        <Heading as="h1" fontSize="9xl" opacity={0.1}>
          {t("title")}
        </Heading>
      </Center>
    </Flex>
  );
}
