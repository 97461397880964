import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { chakra, Table, Tbody, Td, Th, Thead, Tr, useDisclosure, UseDisclosureProps } from "@chakra-ui/react";
import { spacing, useThemeTokens } from "@design-system";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Row,
  TableOptions,
  TableState,
  useReactTable,
} from "@tanstack/react-table";
import { ReactNode } from "react";

export type DataTableProps<Data> = {
  data: Data[];
  columns: ColumnDef<Data>[];
  globalFilter?: TableState["globalFilter"];
  globalFilterFn?: TableOptions<Data>["globalFilterFn"];
  setGlobalFilter?: TableOptions<Data>["onGlobalFilterChange"];
  sorting?: TableState["sorting"];
  setSorting?: TableOptions<Data>["onSortingChange"];
  rowActions?: (data: Row<Data>, disclosureProps: UseDisclosureProps) => ReactNode;
  "row-data-test-id"?: string;
};

function RowActionsCell<Data>({ row, rowActions }: { row: Row<Data>; rowActions: DataTableProps<Data>["rowActions"] }) {
  const disclosureProps = useDisclosure();

  return (
    rowActions && (
      <Td
        key={"rowActions"}
        _groupHover={{ opacity: 1 }}
        opacity={disclosureProps.isOpen ? 1 : 0}
        transition="opacity 0.2s"
      >
        {rowActions(row, disclosureProps)}
      </Td>
    )
  );
}

export function DataTable<Data extends object>({
  data,
  columns,
  globalFilter,
  globalFilterFn,
  sorting,
  setSorting,
  rowActions,
  ...rest
}: DataTableProps<Data>) {
  const {
    background: { color },
  } = useThemeTokens();

  const table = useReactTable({
    columns,
    data,
    getColumnCanGlobalFilter: () => true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      globalFilter,
      sorting,
    },
    enableColumnFilters: true,
    enableSortingRemoval: false,
    enableFilters: true,
    enableGlobalFilter: true,
    globalFilterFn,
    debugTable: true,
  });

  return (
    <Table>
      <Thead position="sticky" top="0" backgroundColor={color}>
        {table.getHeaderGroups().map((headerGroup) => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              const meta = header.column.columnDef.meta;
              const isSorted = header.column.getIsSorted();
              return (
                <Th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  isNumeric={meta && "isNumeric" in meta ? (meta.isNumeric as boolean) : undefined}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}

                  {isSorted && (
                    <chakra.span marginStart={spacing.space[100]} position="relative" top="-4px">
                      {isSorted === "desc" ? (
                        <ChevronDownIcon aria-label="sorted descending" />
                      ) : (
                        <ChevronUpIcon aria-label="sorted ascending" />
                      )}
                    </chakra.span>
                  )}
                </Th>
              );
            })}
            {rowActions && <Th key="rowActions" minW="fit-content" w="5.5rem" />}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {table.getSortedRowModel().rows.map((row) => (
          <Tr key={row.id} role="group" data-test-id={rest["row-data-test-id"]}>
            {row.getVisibleCells().map((cell) => {
              const meta = cell.column.columnDef;

              return (
                <Td key={cell.id} isNumeric={meta && "isNumeric" in meta ? (meta.isNumeric as boolean) : undefined}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              );
            })}

            <RowActionsCell row={row} rowActions={rowActions} />
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}
