import { useProgressToast, useTranslation } from "@hooks";
import { useModelLoader, usePathCollectionLoader } from "@hooks/project";
import { Model, Project } from "@models/backend";
import { useEffect } from "react";
import { useColumnAnchorLoader, useSectionAnchorLoader } from "@hooks";
import { useModelsStore } from "@state";
import { Model as ProjectModel } from "@models/project/Model";
import { PathState } from "@models/project";

type UseSavedModelProps = {
  triggerUploadModelModal: () => void;
  project: Project;
};

export function useLoadProject({ triggerUploadModelModal }: { triggerUploadModelModal?: () => void }) {
  const { clear: clearModels } = useModelsStore(({ clear }) => ({ clear }));
  const { t } = useTranslation("hooks.models.load.progress");
  const toast = useProgressToast();
  const { loadModel } = useModelLoader();
  const { loadPathCollections } = usePathCollectionLoader();
  const { loadSectionAnchors } = useSectionAnchorLoader();
  const { loadColumnAnchors } = useColumnAnchorLoader();

  const loadAnchors = async (loadedModel: ProjectModel, model: Model, guidePath: PathState) => {
    try {
      await loadSectionAnchors(loadedModel, model, guidePath);
      await loadColumnAnchors(loadedModel, model, guidePath);
      return true;
    } catch (error) {
      console.warn("Error loading anchors:", error);
      return false;
    }
  };

  const loadCollections = async (loadedModel: ProjectModel, model: Model) => {
    try {
      const pathCollections = await loadPathCollections(loadedModel, model);
      const guideSource = pathCollections?.find(({ usage }) => usage === "guide")?.paths?.[0];

      if (guideSource) {
        await loadAnchors(loadedModel, model, guideSource);
      }
      return true;
    } catch (error) {
      console.warn("Error loading path collections:", error);
      return false;
    }
  };

  return async (project: Project) => {
    clearModels();

    for (const model of project.models) {
      const { update } = toast(t, { title: model.name, status: "in-progress" });

      try {
        const loadedModel = await loadModel(model);

        update({
          message: t(`status.in-progress.path-collections`),
          status: "in-progress",
        });

        if (!model.parentId) {
          const collectionsLoaded = await loadCollections(loadedModel, model);
          if (!collectionsLoaded) {
            update({
              status: "error",
              message: t("status.error.partialLoad"),
            });
            continue;
          }
        }

        update({ status: "success" });
      } catch (error) {
        console.error("Error loading model:", error);
        update({
          status: "error",
          message: t("status.error.loadFailed"),
        });

        if (!model.parentId && triggerUploadModelModal) {
          triggerUploadModelModal();
        }
      }
    }

    if (!project.models?.length && triggerUploadModelModal) {
      triggerUploadModelModal();
    }
  };
}

export function useSavedModel({ project, triggerUploadModelModal }: UseSavedModelProps) {
  const loadProject = useLoadProject({ triggerUploadModelModal });
  useEffect(() => {
    loadProject(project);
  }, [project]);
}
