import { AnalyticsPlugin, IdentifyData, TrackData } from "../types";

export const consolePlugin: AnalyticsPlugin = {
  name: "analytics-debug-plugin",
  track: ({ payload }: { payload: TrackData }) => {
    console.debug("Event tracked:", {
      event: payload.event,
      properties: payload.properties,
    });
  },
  identify: ({ payload }: { payload: IdentifyData }) => {
    console.debug("User identified:", {
      userId: payload.userId,
      traits: payload.traits,
    });
  },
  loaded: () => true,
};
