import { Box, Container, Flex } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { useSemanticTokens } from "@design-system";
import { SidebarModelReferenceProperties } from "@fragments/project/sidebars/SidebarModelReferenceProperties.tsx";
import { Project as ProjectData } from "@models/backend";
import { useModelsStore } from "@state/models";
import { SidebarHeader } from "./SidebarHeader";
import { SidebarLayers } from "./SidebarLayers";
import { SidebarModelProperties } from "./SidebarModelProperties";
import { useModelName } from "@hooks";
import { SidebarPathCollectionProperties } from "./SidebarPathCollectionProperties";
import { useShallow } from "zustand/react/shallow";
import { SidebarPropertiesHeader } from "./SidebarPropertiesHeader";
import { MeshIcon, PathIcon, ZoneIcon } from "./icons";

interface PropertiesSidebarProps extends StyleProps {
  project: ProjectData;
}

export function Sidebar({ project, ...props }: PropertiesSidebarProps) {
  const {
    border: { border: borderColor },
    icons: { secondary: iconSecondary },
  } = useSemanticTokens();

  const { models, pathCollections, selectedModel, selectedPathCollection, selectedReference, setShowNormals } =
    useModelsStore(
      useShallow(({ models, pathCollections, selectedObjects, setShowNormals }) => {
        const selectedModel = selectedObjects.find(({ type }) => type === "model") as {
          type: "model";
          id: string;
        } | null;
        const selectedPathCollection = selectedObjects.find(({ type }) => type === "path") as {
          type: "path";
          id: string;
          modelId: string;
        } | null;
        const selectedReference = selectedObjects.find(({ type }) => type === "reference") as {
          type: "reference";
          id: string;
          modelId: string;
        } | null;

        return {
          models,
          pathCollections,
          selectedModel,
          selectedPathCollection,
          selectedReference,
          setShowNormals,
        };
      }),
    );

  const selectedModelObject = selectedModel ? models.find((model) => model.id === selectedModel.id) : null;
  const selectedPathCollectionObject = selectedPathCollection
    ? pathCollections[selectedPathCollection.modelId]?.collections[selectedPathCollection.id]
    : null;
  const selectedReferenceObject = selectedReference ? models.find((model) => model.id === selectedReference.id) : null;

  const selectionOptions = [
    {
      type: "model",
      isSelected: !!selectedModelObject,
      name: selectedModelObject?.name,
      icon: <MeshIcon color={iconSecondary} boxSize="1.5rem" strokeWidth="1.5px" />,
      hasProperties: true,
    },
    {
      type: "pathCollection",
      isSelected: !!selectedPathCollectionObject,
      name: selectedPathCollectionObject?.name ?? "Path Collection",
      icon:
        selectedPathCollectionObject?.usage === "zone" && selectedPathCollectionObject?.paths[0].isLoop ? (
          <ZoneIcon color={iconSecondary} boxSize="1.5rem" strokeWidth="1.5px" />
        ) : (
          <PathIcon color={iconSecondary} boxSize="1.5rem" strokeWidth="1.5px" />
        ),
      hasProperties: selectedPathCollectionObject?.usage === "zone" || selectedPathCollectionObject?.usage === "guide",
    },
    {
      type: "reference",
      isSelected: !!selectedReferenceObject,
      name: selectedReferenceObject?.name ?? "Reference",
      icon: <MeshIcon color={iconSecondary} boxSize="1.5rem" strokeWidth="1.5px" />,
      hasProperties: true,
    },
  ];

  const headerProps = selectionOptions.find((option) => option.isSelected);

  return (
    <Container
      as={Flex}
      flexDirection="column"
      height="100%"
      borderLeftWidth={1}
      borderLeftColor={borderColor}
      variant="classic"
      overflow="hidden"
      {...props}
    >
      <Box minHeight={headerProps?.hasProperties ? "50%" : "100%"} maxHeight="calc(100% - 25rem)" overflow="hidden">
        <SidebarHeader height="3rem" title={useModelName(project, selectedModelObject)} data-test-id="project-title" />
        <Box height="calc(100% - 3rem)" overflowY="auto">
          <SidebarLayers project={project} w="full" />
        </Box>
      </Box>
      <Flex flexDirection="column" height="45%" position="relative">
        {headerProps && (
          <>
            <SidebarPropertiesHeader name={headerProps.name ?? ""} icon={headerProps.icon} borderTopWidth={1} />
            <Box flexGrow={1} overflowY="auto">
              {selectedModelObject && (
                <SidebarModelProperties
                  key={selectedModelObject.id}
                  marginTop="auto"
                  height="100%"
                  maxHeight="25rem"
                  overflowY="auto"
                  project={project}
                  borderColor={borderColor}
                  modelId={selectedModelObject.id}
                  setShowNormals={setShowNormals}
                />
              )}
              {selectedPathCollectionObject && selectedPathCollection && (
                <SidebarPathCollectionProperties
                  key={selectedPathCollectionObject.id}
                  marginTop="auto"
                  height="100%"
                  maxHeight="25rem"
                  overflowY="auto"
                  project={project}
                  modelId={selectedPathCollection.modelId}
                  pathCollectionId={selectedPathCollectionObject.id || ""}
                />
              )}
              {selectedReferenceObject && selectedReference && (
                <SidebarModelReferenceProperties
                  key={selectedReferenceObject.id}
                  marginTop="auto"
                  height="100%"
                  maxHeight="25rem"
                  overflowY="auto"
                  reference={selectedReferenceObject}
                />
              )}
            </Box>
          </>
        )}
      </Flex>
    </Container>
  );
}
