import { CoreAnalytics } from "./CoreAnalytics";

const globalWithAnalytics = window as { _analytics?: CoreAnalytics };

if (!globalWithAnalytics._analytics) {
  globalWithAnalytics._analytics = CoreAnalytics.setupCoreAnalytics();
}

export const analytics = globalWithAnalytics._analytics as CoreAnalytics;
export type { TrackData, UserTraits } from "./types";
export { EventTrackingError, AnalyticsError } from "./types";
