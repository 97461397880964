import { Accordion, AccordionItem, Container, Text } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { spacing, useSemanticTokens } from "@design-system";
import { useModelApi, useCalibrationCurves, useTranslation } from "@hooks";
import { useBackendPathCollection } from "@hooks/project/useBackendPathCollection";
import { PathCollection, Project } from "@models/backend";
import { Model } from "@models/project";
import { useModelsStore } from "@state/models";
import { ReactElement, ReactNode, useCallback, useMemo } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ColumnIcon, GuideCurveIcon, MeshIcon, PathIcon, SectionIcon, ZoneIcon } from "./icons";
import { SidebarGroup } from "./SidebarGroup";
import { LayerData, SidebarLayer } from "./SidebarLayer";
import { useProjectState } from "@state";

type PathInfo = {
  key: string;
  name: string;
  icon: ReactElement;
  layerId: string;
  layerType: "path";
  group: "guide" | "zone" | "unused";
  isLoop: boolean;
  selected: boolean;
  hovered: boolean;
  onSelect: () => void;
  onHover: () => void;
  onUnhover: () => void;
  isEditable: boolean;
};

type SectionInfo = {
  key: string;
  name: string;
  icon: ReactElement;
  layerId: string;
  layerType: "section";
  group: "section";
  selected: boolean;
  hovered: boolean;
  onSelect: () => void;
  onHover: () => void;
  onUnhover: () => void;
};

type ColumnInfo = {
  key: string;
  name: string;
  icon: ReactElement;
  layerId: string;
  layerType: "column";
  group: "column";
  selected: boolean;
  hovered: boolean;
  onSelect: () => void;
  onHover: () => void;
  onUnhover: () => void;
};

const classifiedLayers = (paths: Array<PathInfo>, onChange: (layerId: string, newValue: string) => void) => {
  const guidePath: Array<ReactNode> = [];
  const zones: Array<ReactNode> = [];
  const unused: Array<ReactNode> = [];

  paths.forEach(({ key, name, icon, layerId, layerType, group, isLoop, ...rest }) => {
    const handleLayerChange = (newValue: string) => {
      onChange(layerId, newValue);
    };

    const layer = (
      <SidebarLayer
        key={key}
        name={name}
        icon={icon}
        layerType={layerType}
        layerData={{ id: layerId, group, isLoop }}
        displacement={1}
        secondary={group === "unused"}
        onChange={handleLayerChange}
        {...rest}
        data-test-id={`${group}-path-layer`}
      />
    );

    switch (group) {
      case "guide":
        guidePath.push(layer);
        break;
      case "zone":
        zones.push(layer);
        break;
      default:
        unused.push(layer);
    }
  });

  return [guidePath, zones, unused];
};

const sectionLayers = (sections: Array<SectionInfo>) => {
  const sectionsGroup: Array<ReactNode> = [];
  sections.forEach(({ key, name, icon, layerId, layerType, group, ...rest }) => {
    const layer = (
      <SidebarLayer
        key={key}
        name={name}
        icon={icon}
        layerType={layerType}
        layerData={{ id: layerId, group, isLoop: false }}
        displacement={1}
        secondary={false}
        isEditable={false}
        data-test-id={`${group}-layer`}
        {...rest}
      />
    );
    sectionsGroup.push(layer);
  });
  return [sectionsGroup];
};

const columnLayers = (columns: Array<ColumnInfo>) => {
  const columnsGroup: Array<ReactNode> = [];
  columns.forEach(({ key, name, icon, layerId, layerType, group, ...rest }) => {
    const layer = (
      <SidebarLayer
        key={key}
        name={name}
        icon={icon}
        layerType={layerType}
        layerData={{ id: layerId, group, isLoop: false }}
        displacement={1}
        secondary={false}
        isEditable={false}
        data-test-id={`${group}-layer`}
        {...rest}
      />
    );
    columnsGroup.push(layer);
  });
  return [columnsGroup];
};

type ModelLayerProps = {
  project: Project;
  model: Model;
  paths: Array<PathInfo>;
  sections: Array<SectionInfo>;
  columns: Array<ColumnInfo>;
  onSelect: () => void;
  onHover: () => void;
  onUnhover: () => void;
  onChange: (modelId: string, layerId: string | null, newValue: string) => void;
  selected: boolean;
  hovered: boolean;
};

function ModelLayer({ project, model, paths, sections, columns, onChange, ...rest }: ModelLayerProps) {
  const { id: projectId } = project;
  const {
    changePathCollection,
    pathCollections,
    selectedObjects,
    setSelectedObjects,
    hoveredObject,
    setHoveredObject,
    getSectionAnchors,
    getColumnAnchors,
  } = useModelsStore(
    ({
      changePathCollection,
      pathCollections,
      selectedObjects,
      setSelectedObjects,
      hoveredObject,
      setHoveredObject,
      getSectionAnchors,
      getColumnAnchors,
    }) => ({
      changePathCollection,
      pathCollections,
      selectedObjects,
      setSelectedObjects,
      hoveredObject,
      setHoveredObject,
      getSectionAnchors,
      getColumnAnchors,
    }),
  );
  const { set3DToolbarSelection } = useProjectState();
  const { updateBackendPathCollection } = useBackendPathCollection();
  const { deleteReferenceModel } = useModelApi();
  const { getAutoColumns, getAutoSections } = useCalibrationCurves({ project, model });
  const { t } = useTranslation("project.sidebar.layers.groups");

  const changeAndSave = useCallback(
    async (layerData: LayerData | undefined, usage: PathCollection["usage"]) => {
      if (layerData) {
        const changed = changePathCollection(model.id, layerData.id, { usage });

        const guideSource = changed.find((collection) => collection.usage === "guide");
        if (guideSource) {
          const currentSections = getSectionAnchors(model.id);
          const numAutoSections = model.attributes.sections?.count || 0;

          const currentColumns = getColumnAnchors(model.id);
          const numAutoColumns = model.attributes.columns?.count || 0;

          await getAutoSections(guideSource.paths[0], numAutoSections, currentSections);
          await getAutoColumns(guideSource.paths[0], numAutoColumns, currentColumns);
        }

        for (const collection of changed) {
          updateBackendPathCollection(projectId, model.id, collection);
        }
      }
    },
    [changePathCollection, model.id, updateBackendPathCollection, projectId],
  );

  const [guidePath, zones, unused] = useMemo(() => {
    const updatedPaths = paths.map((path) => {
      const backendCollection = pathCollections[model.id]?.collections[path.layerId];
      return {
        ...path,
        name: backendCollection?.name || path.name,
      };
    });

    return classifiedLayers(updatedPaths, (layerId, newValue) => onChange(model.id, layerId, newValue));
  }, [paths, pathCollections, model.id, onChange]);

  const [sectionsGroup] = useMemo(() => sectionLayers(sections), [sections]);
  const [columnsGroup] = useMemo(() => columnLayers(columns), [columns]);

  return (
    <AccordionItem p={0}>
      <SidebarLayer
        isEditable={true}
        name={model.name}
        icon={<MeshIcon />}
        layerType="model"
        onChange={(newName: string) => onChange(model.id, null, newName)}
        {...rest}
      >
        <SidebarGroup
          name={t("guide")}
          canDrop={({ type, layerData }) => type === "path" && !layerData?.isLoop}
          drop={({ layerData }) => changeAndSave(layerData, "guide")}
          replace={true}
          group="guide"
        >
          {guidePath}
        </SidebarGroup>
        <SidebarGroup
          name={t("section")}
          canDrop={() => false}
          drop={({ layerData }) => changeAndSave(layerData, null)}
          replace={false}
          group="section"
        >
          {sectionsGroup}
        </SidebarGroup>
        <SidebarGroup
          name={t("column")}
          canDrop={() => false}
          drop={({ layerData }) => changeAndSave(layerData, null)}
          replace={false}
          group="column"
        >
          {columnsGroup}
        </SidebarGroup>
        <SidebarGroup
          name={t("zone")}
          canDrop={({ type }) => type === "path"}
          drop={({ layerData }) => changeAndSave(layerData, "zone")}
          replace={false}
          group="zone"
        >
          {zones}
        </SidebarGroup>
        <SidebarGroup name={t("reference")} canDrop={() => false} drop={() => {}} replace={false} group="reference">
          {model.references.map((referenceModel) => {
            const id = referenceModel.id;

            return (
              <SidebarLayer
                key={id}
                name={referenceModel.name}
                icon={<MeshIcon />}
                layerData={{ id, group: "reference" }}
                displacement={1}
                isEditable={true}
                secondary={false}
                selected={!!selectedObjects.find((o) => o.id === id)}
                hovered={hoveredObject?.id === id}
                onSelect={() => {
                  setSelectedObjects([{ id, type: "reference", modelId: model.id }]);
                  set3DToolbarSelection("select");
                }}
                onHover={() => setHoveredObject({ id, type: "reference", modelId: model.id })}
                onUnhover={() => setHoveredObject(null)}
                onDelete={() => deleteReferenceModel(projectId, id)}
              />
            );
          })}
        </SidebarGroup>
        <SidebarGroup
          name={t("unused")}
          canDrop={({ type }) => type === "path"}
          drop={({ layerData }) => changeAndSave(layerData, null)}
          replace={false}
          group="unused"
        >
          {unused}
        </SidebarGroup>
      </SidebarLayer>
    </AccordionItem>
  );
}

export function SidebarLayers({ project, ...props }: StyleProps & { project: Project }) {
  const { id: projectId } = project;
  const models = useModelsStore((state) => state.models).filter((model) => !model.parentId);
  const { columns, pathCollections, sections } = useModelsStore((s) => s);
  const { selectedObjects, setSelectedObjects, hoveredObject, setHoveredObject, changePathCollection } =
    useModelsStore();
  const { updateBackendPathCollection } = useBackendPathCollection();
  const { set3DToolbarSelection } = useProjectState();

  const { t } = useTranslation("project.sidebar.layers");
  const semanticTokens = useSemanticTokens();
  const { patchModel } = useModelApi();

  const handleRename = useCallback(
    (modelId: string, layerId: string | null, newValue: string) => {
      if (layerId === null) {
        patchModel(projectId, modelId, { name: newValue }).catch((error) => {
          console.error(`Failed to update model name:`, error);
        });
      } else {
        const updatedCollections = changePathCollection(modelId, layerId, { name: newValue });

        if (updatedCollections.length > 0) {
          const updatedCollection = updatedCollections[0];
          updateBackendPathCollection(projectId, modelId, updatedCollection).catch((error) => {
            console.error("Failed to update backend:", error);
            changePathCollection(modelId, layerId, { name: updatedCollection.name });
          });
        }
      }
    },
    [changePathCollection, updateBackendPathCollection, patchModel, projectId],
  );

  const pathsInfo: { [key: string]: Array<PathInfo> } = useMemo(() => {
    const result: { [key: string]: Array<PathInfo> } = {};
    for (const { id: modelKey } of models) {
      result[modelKey] = [];
      for (const colKey in pathCollections[modelKey]?.collections) {
        const { usage, paths, name } = pathCollections[modelKey].collections[colKey];
        if (paths.length != 1) {
          // TODO in the future paths can be multiple.
        }
        const isLoop = !!paths[0].isLoop;
        const icon =
          usage === "zone" ? (
            isLoop ? (
              <ZoneIcon />
            ) : (
              <PathIcon />
            )
          ) : usage === "guide" ? (
            <GuideCurveIcon />
          ) : (
            <PathIcon />
          );
        const layerName = name || t("types.unnamed");
        const group = usage ?? "unused";
        const layer: PathInfo = {
          key: colKey,
          name: layerName,
          icon,
          layerId: colKey,
          layerType: "path",
          group,
          isLoop,
          selected: !!selectedObjects.find((o) => o.id === colKey),
          hovered: hoveredObject?.id === colKey,
          onSelect: () => {
            setSelectedObjects([{ id: colKey, type: "path", modelId: modelKey }]);
            set3DToolbarSelection("select");
          },
          onHover: () => setHoveredObject({ id: colKey, type: "path", modelId: modelKey }),
          onUnhover: () => setHoveredObject(null),
          isEditable: true,
        };
        result[modelKey].push(layer);
      }
    }
    return result;
  }, [pathCollections, models, selectedObjects, hoveredObject, setSelectedObjects, setHoveredObject, t]);

  const sectionsInfo: { [key: string]: Array<SectionInfo> } = useMemo(() => {
    const result: { [key: string]: Array<SectionInfo> } = {};
    for (const { id: modelKey } of models) {
      result[modelKey] = [];
      sections[modelKey]?.sectionAnchors.map((sectionAnchor, index) => {
        const id = sectionAnchor.id!;
        const icon = <SectionIcon />;
        const layer: SectionInfo = {
          key: id,
          name: "Section " + (index + 1),
          icon,
          layerId: id,
          layerType: "section",
          group: "section",
          selected: !!selectedObjects.find((o) => o.id === id),
          hovered: hoveredObject?.id === id,
          onSelect: () => {
            setSelectedObjects([{ id: id, type: "section", modelId: modelKey }]);
            set3DToolbarSelection("select");
          },
          onHover: () => setHoveredObject({ id: id, type: "section", modelId: modelKey }),
          onUnhover: () => setHoveredObject(null),
        };
        result[modelKey].push(layer);
      });
    }
    return result;
  }, [sections, models, selectedObjects, hoveredObject, setSelectedObjects, setHoveredObject]);

  const columnsInfo: { [key: string]: Array<ColumnInfo> } = useMemo(() => {
    const result: { [key: string]: Array<ColumnInfo> } = {};
    for (const { id: modelKey } of models) {
      result[modelKey] = [];
      columns[modelKey]?.columnAnchors.map((columnAnchor, index) => {
        const id = columnAnchor.id!;
        const icon = <ColumnIcon />;
        const layer: ColumnInfo = {
          key: id,
          name: "Column " + (index + 1),
          icon,
          layerId: id,
          layerType: "column",
          group: "column",
          selected: !!selectedObjects.find((o) => o.id === id),
          hovered: hoveredObject?.id === id,
          onSelect: () => {
            setSelectedObjects([{ id: id, type: "column", modelId: modelKey }]);
            set3DToolbarSelection("select");
          },
          onHover: () => setHoveredObject({ id: id, type: "column", modelId: modelKey }),
          onUnhover: () => setHoveredObject(null),
        };
        result[modelKey].push(layer);
      });
    }
    return result;
  }, [columns, models, selectedObjects, hoveredObject, setSelectedObjects, setHoveredObject]);

  if (!models.length) {
    return (
      <Text color={semanticTokens.text.invert.tertiary} padding={spacing.space[200]} variant="2xs-medium">
        {t("emptyState")}
      </Text>
    );
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Container as={Accordion} variant="classic" allowMultiple defaultIndex={models.map((_, i) => i)} {...props}>
        {models.map((model) => (
          <ModelLayer
            key={model.id}
            project={project}
            model={model}
            paths={pathsInfo[model.id]}
            sections={sectionsInfo[model.id]}
            columns={columnsInfo[model.id]}
            onHover={() => setHoveredObject({ id: model.id, type: "model" })}
            onUnhover={() => setHoveredObject(null)}
            onSelect={() => {
              setSelectedObjects([{ type: "model", id: model.id }]);
              set3DToolbarSelection("select");
            }}
            selected={!!selectedObjects.find((o) => o.id === model.id)}
            hovered={hoveredObject?.id === model.id}
            onChange={handleRename}
          />
        ))}
      </Container>
    </DndProvider>
  );
}
