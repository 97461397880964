import { Box } from "@chakra-ui/react";
import { KnitStructure } from "@models/backend";

export const StructureLabel = ({ structure }: { structure?: KnitStructure }) => {
  if (!structure) {
    return "";
  }
  return (
    <>
      <Box float="left" width="20px" height="20px" marginRight="5px" bg={structure?.color ?? undefined} />
      {structure?.name ?? ""}
    </>
  );
};
