import { analytics, EventTrackingError } from "@integrations/analytics";
import { captureException } from "@sentry/react";
import { BITMAP_EXPORT_FAILED, BITMAP_EXPORTED } from "./eventNames";

interface BitmapExportedProperties {
  projectId: string;
  status: "started" | "success" | "failed";
  size?: number;
  error?: string;
}

export function trackBitmapExported(properties: BitmapExportedProperties): void {
  const eventName = properties.status === "failed" ? BITMAP_EXPORT_FAILED : BITMAP_EXPORTED;

  analytics.track(eventName, properties).catch((error: Error) => {
    const trackingError = new EventTrackingError(eventName, error, { properties });
    captureException(trackingError);
  });
}
