import { Button, Center } from "@chakra-ui/react";
import {
  browserProfilingIntegration,
  browserTracingIntegration,
  init,
  reactRouterV6BrowserTracingIntegration,
  replayCanvasIntegration,
  replayIntegration,
  withErrorBoundary,
  withProfiler,
} from "@sentry/react";
import React, { useEffect } from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

export function initSentry(App: React.FC) {
  if (import.meta.env.PROD) {
    if (!import.meta.env.VITE_SENTRY_DSN || !import.meta.env.VITE_SENTRY_ENV) {
      throw new Error("VITE_SENTRY_DSN and VITE_SENTRY_ENV must be set");
    }

    init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      environment: import.meta.env.VITE_SENTRY_ENV,
      integrations: [
        browserProfilingIntegration(),
        browserTracingIntegration(),
        reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
        replayCanvasIntegration(),
      ],
      profilesSampleRate: 1.0,
      replaysSessionSampleRate: 0.0,
      replaysOnErrorSampleRate: 1.0,
      tracePropagationTargets: [/^\/api/],
      tracesSampleRate: 1.0,
    });

    return withProfiler(
      withErrorBoundary(App, {
        fallback: ({ error, eventId, componentStack }) => (
          <Center flex="1 1 auto" h="full">
            It's not you! It's us.
            <br />
            Error <code>{eventId}</code> has been reported
            <div>
              <code>{error?.toString()}</code>
              <pre>{componentStack}</pre>
            </div>
            <Button variant="primary" onClick={() => window.location.reload()}>
              Go back
            </Button>
          </Center>
        ),
      }),
    );
  }

  return App;
}
