import "@/globals";
import { ColumnAnchor, Model, PathCollection, SectionAnchor } from "@models/project";
import { KnitStructure } from "@models/backend";
import { getV3dApi } from "@utils/project/initV3dApi.ts";
import { BasePath, Path } from "@variant-tech/pattern-derivation";
import { exportMeshToGLB, getMeshFromMesh3DBase } from "./mesh";
import { defaultsDeep } from "lodash";
import {
  modelAttributes as defaultModelAttributes,
  pathCollectionAttributes as defaultPathCollectionAttributes,
} from "@defaults";
export default async function exportBMP(
  model: Model,
  guideSource: BasePath,
  collections: PathCollection[],
  sectionAnchors: SectionAnchor[],
  columnAnchors: ColumnAnchor[],
  defaultKnitStructure: KnitStructure,
  binary = true,
  debug = false,
): Promise<File> {
  const v3dApi = getV3dApi();
  const part = await v3dApi.Part.create(
    model.mesh3DBase,
    guideSource as Path,
    collections.map(({ cpp }) => cpp),
  );
  let modelAttributes = {
    ...model?.attributes,
    knitStructure: model?.attributes?.knitStructure ?? defaultKnitStructure,
  };
  modelAttributes = defaultsDeep(modelAttributes, defaultModelAttributes);
  await part.computeFieldValues();

  const includeCalibrationGrid = modelAttributes.calibrationGrid?.include;
  const wale = modelAttributes.stitchDensity?.wale ?? modelAttributes.knitStructure.waleDensity ?? 0.0;
  // Using the value 0.0, to revert to use a default value based on the mesh bounding box
  await part.computeIsolineCurves(1.0 / wale);

  const sectionAnchorPoints = sectionAnchors.map((sectionAnchor) => sectionAnchor.point);
  await part.setSectionAnchors(sectionAnchorPoints);

  const columnAnchorPoints = columnAnchors.map((columnAnchor) => columnAnchor.point);
  await part.setColumnAnchors(columnAnchorPoints);

  await part.computeIsolineSections(0.0);
  await part.computeIsolineZones();

  const extrasPayload = await part.getExtrasPayload();

  const partMesh3DBase = await part.getMesh3DBase();

  const partMesh = await getMeshFromMesh3DBase(partMesh3DBase);

  function createKnitStructuresObject() {
    const defaultKnitStructure = {
      ...modelAttributes.knitStructure,
      courseDensity: modelAttributes.stitchDensity?.course ?? modelAttributes.knitStructure.courseDensity,
      waleDensity: modelAttributes.stitchDensity?.wale ?? modelAttributes.knitStructure.waleDensity,
    };

    const zones = collections.map((zone) => {
      const knitStructure = zone.attributes?.knitStructure ?? modelAttributes.knitStructure;
      const courseDensity = zone.attributes?.stitchDensity?.course ?? knitStructure.courseDensity;
      const transition = zone.attributes?.transition ?? defaultPathCollectionAttributes.transition;
      return { ...knitStructure, courseDensity, transition };
    });

    return {
      default: defaultKnitStructure,
      zones,
    };
  }

  const knitStructures = createKnitStructuresObject();

  partMesh.geometry.userData = {
    ...extrasPayload,
    knitParameters: {
      knitStructures,
      calibrationGrid: {
        include: includeCalibrationGrid,
      },
    },
  };
  console.log(partMesh.geometry.userData);
  await partMesh3DBase.delete();
  await part.delete();

  return exportMeshToGLB(partMesh, "scene", binary, debug);
}
