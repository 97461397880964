import { Mesh3DBase } from "@variant-tech/pattern-derivation";
import { BufferAttribute, BufferGeometry } from "three";

export async function getZoneGeo(mesh3DBase: Mesh3DBase) {
  const vertices = await mesh3DBase.verticesAsFloat32Buffer();
  const faces = await mesh3DBase.facesAsInt32Buffer();
  const geometry = new BufferGeometry();
  const indices: number[] = [];
  faces.forEach((face: number) => {
    indices.push(face);
  });

  geometry.setIndex(indices);
  geometry.setAttribute("position", new BufferAttribute(vertices, 3));
  geometry.computeVertexNormals();

  return geometry;
}
