import { Accordion, Button, Container, HStack, Input, Switch, Text } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { Dropdown, DropdownItem, IconButton, spacing, useSemanticTokens, useThemeTokens } from "@design-system";
import { useModelUpdate, useCalibrationCurves, useTranslation } from "@hooks";
import { KnitStructure, Project as ProjectData, Unit, Units } from "@models/backend";
import { Model } from "@models/project";
import { useCallback, useState } from "react";
import { FlipIcon, RecycleIcon } from "./icons";
import { SidebarPropertiesSection } from "./SidebarPropertiesSection";
import { useModelsStore } from "@state";
import { recordToArray, withLoadingAndErrorHandling } from "@utils";
import { StructureLabel } from "./Common";

type ModelPropertiesProps = {
  modelId: Model["id"];
  project: ProjectData;
  setShowNormals: (show: boolean) => void;
} & StyleProps;

export function SidebarModelProperties({ modelId, project, setShowNormals, ...props }: ModelPropertiesProps) {
  const { t } = useTranslation("projectProperties.propertiesSidebar.tools");
  const { model, flipNormals, changeAttributes, changeUnit } = useModelUpdate({ modelId, projectId: project.id });
  const { columns, pathCollections, sections, knitStructures } = useModelsStore((s) => s);
  const { collections } = pathCollections[model.id] ?? { collections: {}, newCollection: null };
  const arrayCollections = recordToArray(collections);
  const { getAutoColumns, getAutoSections } = useCalibrationCurves({ project, model });
  const semanticTokens = useSemanticTokens();
  const textClassicSecondary = semanticTokens.text.classic.secondary;
  const borderColor = semanticTokens.border.border;

  const { border } = useThemeTokens();
  const unitOptions = Object.keys(Units) as Unit[];

  const defaultStructure = knitStructures.find(({ isDefault }) => isDefault);
  const selectedStructure = model?.attributes?.knitStructure ?? defaultStructure;
  const isStitchDensityDefault = !model?.attributes?.stitchDensity;
  const includeCalibrationGrid = model?.attributes?.calibrationGrid?.include;

  const [selectedCourse, setSelectedCourse] = useState<string>(
    model?.attributes?.stitchDensity?.course?.toFixed(3) ?? "",
  );
  const [selectedWale, setSelectedWale] = useState<string>(model?.attributes?.stitchDensity?.wale?.toFixed(3) ?? "");

  const [sectionsCount, setSectionsCount] = useState<string>(model?.attributes?.sections?.count?.toString() ?? "10");
  const [columnsCount, setColumnsCount] = useState<string>(model?.attributes?.columns?.count?.toString() ?? "10");

  const [isLoadingSections, setIsLoadingSections] = useState(false);
  const [loadSectionsError, setLoadSectionsError] = useState<Error | null>(null);

  const [isLoadingColumns, setIsLoadingColumns] = useState(false);
  const [loadColumnsError, setLoadColumnsError] = useState<Error | null>(null);

  const [isLoadingFlipNormals, setIsLoadingFlipNormals] = useState(false);

  // TODO: how will we handle translation of knit structure options?
  const setKnitStructure = useCallback(
    async (newStructure: KnitStructure) => {
      await changeAttributes({
        knitStructure: newStructure,
      });
    },
    [changeAttributes],
  );

  const setIncludeCalibrationGrid = useCallback(
    async (include: boolean) => {
      await changeAttributes({
        calibrationGrid: { include },
      });
    },
    [changeAttributes],
  );

  const saveCourse = useCallback(async () => {
    const course = isNaN(parseFloat(selectedCourse)) ? null : parseFloat(selectedCourse);
    await changeAttributes({ stitchDensity: { course } });
    setSelectedCourse(course !== null ? course.toFixed(3) : "");
  }, [selectedCourse, changeAttributes]);

  const saveWale = useCallback(async () => {
    const wale = isNaN(parseFloat(selectedWale)) ? null : parseFloat(selectedWale);
    await changeAttributes({ stitchDensity: { wale } });
    setSelectedWale(wale !== null ? wale.toFixed(3) : "");
  }, [selectedWale, changeAttributes]);

  const saveSectionsCount = useCallback(async () => {
    const count = Math.max(0, parseInt(sectionsCount) || 0);
    setSectionsCount(count.toString());
    await changeAttributes({
      sections: {
        ...model?.attributes?.sections,
        count,
      },
    });
  }, [sectionsCount, changeAttributes, model?.attributes?.sections]);

  const getAndSetSections = useCallback(
    async (numAutoSections: number) => {
      withLoadingAndErrorHandling(setIsLoadingSections, setLoadSectionsError, async () => {
        const guideSource = arrayCollections.find((pathCollection) => pathCollection.usage === "guide");
        const existingSectionAnchors = sections[model.id].sectionAnchors;
        if (guideSource) {
          return (await getAutoSections(guideSource.paths[0], numAutoSections, existingSectionAnchors)) ?? [];
        }
      });
      if (loadSectionsError) {
        // already have toast notification deeper within getAutoSections
        console.error(loadSectionsError);
      }
    },
    [getAutoSections],
  );

  const saveColumnsCount = useCallback(async () => {
    const count = Math.max(0, parseInt(columnsCount) || 0);
    setColumnsCount(count.toString());
    await changeAttributes({
      columns: {
        ...model?.attributes?.columns,
        count,
      },
    });
  }, [columnsCount, changeAttributes, model?.attributes?.columns]);

  const getAndSetColumns = useCallback(
    async (numAutoColumns: number) => {
      withLoadingAndErrorHandling(setIsLoadingColumns, setLoadColumnsError, async () => {
        const guideSource = arrayCollections.find((pathCollection) => pathCollection.usage === "guide");
        const existingColumnAnchors = columns[model.id].columnAnchors;
        if (guideSource) {
          return (await getAutoColumns(guideSource.paths[0], numAutoColumns, existingColumnAnchors)) ?? [];
        }
      });
      if (loadColumnsError) {
        // already have toast notification deeper within getAutoColumns
        console.error(loadColumnsError);
      }
    },
    [getAutoColumns],
  );

  if (!model) {
    return null;
  }
  console.log("knitStructure", knitStructures);
  return (
    <Container variant="classic" borderTopWidth={border.width} {...props} height="full">
      <Accordion
        variant="outline"
        borderTop="unset"
        allowMultiple
        defaultIndex={[0, 1, 2, 3]}
        paddingX={spacing.space["200"]}
        w="full"
        borderColor={borderColor}
      >
        <SidebarPropertiesSection borderTop="unset" title={t("meshSettings.label")}>
          <HStack justifyContent="space-between" w="full">
            <Text color={textClassicSecondary} variant="2xs-regular" width="50%">
              {t("meshSettings.units.label")}
            </Text>
            <Dropdown
              variant="classic-dropdown"
              label={model.unit}
              buttonStyleProps={{
                size: "sm",
                width: "50%",
                paddingX: spacing.space["300"],
                height: spacing.space["600"],
                gap: spacing.space["200"],
                paddingRight: spacing.space["100"],
              }}
              menuListStyleProps={{
                paddingY: spacing.space["100"],
              }}
            >
              {unitOptions.map((unit) => (
                <DropdownItem
                  key={unit}
                  label={t(`meshSettings.units.${unit}.label`)}
                  value={unit}
                  selectedValue={model.unit}
                  setSelectedValue={async (selectedUnit) => {
                    await changeUnit(selectedUnit);
                  }}
                />
              ))}
            </Dropdown>
          </HStack>
          <HStack justifyContent="space-between" w={"100%"}>
            <Text color={textClassicSecondary} variant="2xs-regular" width="50%">
              {t("meshSettings.sections.label")}
            </Text>
            <Input
              size="sm"
              width="50%"
              height={spacing.space["600"]}
              type="number"
              min="0"
              placeholder="10"
              isDisabled={isLoadingSections}
              value={sectionsCount}
              onChange={(e) => setSectionsCount(e.target.value)}
              onBlur={async () => {
                saveSectionsCount();
                await getAndSetSections(parseInt(sectionsCount));
              }}
            />
            <IconButton
              onClick={async () => {
                await getAndSetSections(parseInt(sectionsCount));
              }}
              hideLabel
              isDisabled={isLoadingSections}
              size="xs"
              variant="outline"
              width={spacing.space["800"]}
              leftIcon={<RecycleIcon width={spacing.space["400"]} />}
              label={t("meshSettings.sections.regenerate.label")}
              tooltipPlacement="top"
            />
          </HStack>
          <HStack justifyContent="space-between" w={"100%"}>
            <Text color={textClassicSecondary} variant="2xs-regular" width="50%">
              {t("meshSettings.columns.label")}
            </Text>
            <Input
              size="sm"
              width="50%"
              height={spacing.space["600"]}
              type="number"
              min="0"
              placeholder="10"
              isDisabled={isLoadingColumns}
              value={columnsCount}
              onChange={(e) => setColumnsCount(e.target.value)}
              onBlur={async () => {
                saveColumnsCount();
                await getAndSetColumns(parseInt(columnsCount));
              }}
            />
            <IconButton
              onClick={async () => {
                await getAndSetColumns(parseInt(columnsCount));
              }}
              hideLabel
              isDisabled={isLoadingColumns}
              size="xs"
              variant="outline"
              width={spacing.space["800"]}
              leftIcon={<RecycleIcon width={spacing.space["400"]} />}
              label={t("meshSettings.columns.regenerate.label")}
              tooltipPlacement="top"
            />
          </HStack>
          <HStack justifyContent="space-between" w={"100%"}>
            <Text color={textClassicSecondary} variant="2xs-regular" w="50%">
              {t("meshSettings.flipNormals.label")}
            </Text>
            <Button
              isDisabled={isLoadingFlipNormals}
              onClick={async () => {
                setIsLoadingFlipNormals(true);
                try {
                  await flipNormals();
                } finally {
                  setIsLoadingFlipNormals(false);
                }
              }}
              onPointerEnter={() => {
                setShowNormals(true);
              }}
              onPointerLeave={() => {
                setShowNormals(false);
              }}
              size="xs"
              variant="outline"
              width={spacing.space["800"]}
            >
              <FlipIcon width={spacing.space["400"]} />
            </Button>
          </HStack>
        </SidebarPropertiesSection>
        <SidebarPropertiesSection title={t("meshSettings.knitStructure.label")}>
          <HStack justifyContent="space-between" w="full">
            <Dropdown
              variant="classic-dropdown"
              label={<StructureLabel structure={selectedStructure} />}
              buttonStyleProps={{
                size: "sm",
                width: "100%",
                height: spacing.space["600"],
              }}
              menuListStyleProps={{
                paddingY: spacing.space["100"],
                maxHeight: "200px",
                overflowY: "auto",
              }}
            >
              {knitStructures.map((structure) => (
                <DropdownItem
                  key={structure.id}
                  content={<StructureLabel structure={structure} />}
                  value={structure.id}
                  selectedValue={selectedStructure?.id}
                  setSelectedValue={async () => {
                    setKnitStructure(structure);
                  }}
                />
              ))}
            </Dropdown>
          </HStack>
        </SidebarPropertiesSection>
        <SidebarPropertiesSection title={t("stitchDensity.label")}>
          <HStack justifyContent="space-between" w={"100%"}>
            <Text color={textClassicSecondary} variant="2xs-regular">
              {t("stitchDensity.default.knitStructure.label")}
            </Text>
            <Switch
              isChecked={isStitchDensityDefault}
              marginLeft="auto"
              size="sm"
              onChange={async (e) => {
                if (e.target.checked) {
                  await changeAttributes({ stitchDensity: null });
                } else {
                  const course = selectedStructure?.courseDensity ?? 0;
                  const wale = selectedStructure?.waleDensity ?? 0;
                  await changeAttributes({ stitchDensity: { course, wale } });
                  setSelectedWale(wale.toFixed(3));
                  setSelectedCourse(course.toFixed(3));
                }
              }}
            />
          </HStack>
          <HStack justifyContent="space-between" w={"100%"}>
            <Text color={textClassicSecondary} variant="2xs-regular" width="50%">
              {t("stitchDensity.course.label")}
            </Text>
            <Input
              disabled={isStitchDensityDefault}
              size="sm"
              width="50%"
              height={spacing.space["600"]}
              type="number"
              placeholder="0"
              onChange={(e) => setSelectedCourse(e.target.value)}
              onBlur={saveCourse}
              value={isStitchDensityDefault ? selectedStructure?.courseDensity?.toFixed(3) : selectedCourse}
            />
          </HStack>
          <HStack justifyContent="space-between" w={"100%"}>
            <Text color={textClassicSecondary} variant="2xs-regular" width="50%">
              {t("stitchDensity.wale.label")}
            </Text>
            <Input
              disabled={isStitchDensityDefault}
              size="sm"
              width="50%"
              height={spacing.space["600"]}
              type="number"
              placeholder="0"
              onChange={(e) => setSelectedWale(e.target.value)}
              onBlur={saveWale}
              value={isStitchDensityDefault ? selectedStructure?.waleDensity?.toFixed(3) : selectedWale}
            />
          </HStack>
        </SidebarPropertiesSection>
        <SidebarPropertiesSection title={t("bitmapSettings.label")}>
          <HStack justifyContent="space-between" w="full">
            <Text color={textClassicSecondary} variant="2xs-regular">
              {t("bitmapSettings.calibrationGrid.label")}
            </Text>
            <Switch
              isChecked={includeCalibrationGrid}
              marginLeft="auto"
              size="sm"
              onChange={(e) => setIncludeCalibrationGrid(e.target.checked)}
            />
          </HStack>
        </SidebarPropertiesSection>
      </Accordion>
    </Container>
  );
}
