import { useCallback, useState } from "react";
import { useAuth } from "@auth";
import { useModelsStore } from "@state/models";
import BackendApi from "@api/BackendApi";
import { KnitStructure } from "@models/backend";
import { withLoadingAndErrorHandling } from "@utils";

function transformKnitStructure(structure: KnitStructure) {
  return {
    ...structure,
    courseDensity: structure.courseDensity ? Number(structure.courseDensity) : null,
    waleDensity: structure.waleDensity ? Number(structure.waleDensity) : null,
  };
}

export function useLoadKnitStructures() {
  const { headers } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const setKnitStructures = useModelsStore((state) => state.setKnitStructures);
  const knitStructures = useModelsStore((state) => state.knitStructures);

  const fetchKnitStructures = useCallback(async () => {
    return withLoadingAndErrorHandling(setIsLoading, setError, async () => {
      const response = await BackendApi.getKnitStructures({
        headers,
      });

      const transformedStructures = response.result.structures.map(transformKnitStructure);

      setKnitStructures(transformedStructures);
      return transformedStructures;
    });
  }, [headers, setKnitStructures]);

  return {
    fetchKnitStructures,
    structures: knitStructures,
    isLoading,
    error,
  };
}
