import { Organization, useAuth } from "@auth";
import { Avatar, Box, Container, HStack, Spacer, Text, VStack } from "@chakra-ui/react";
import { spacing, useSemanticTokens, useThemeTokens } from "@design-system";
import { ApplicationContextMenu } from "@fragments";
import { useTranslation } from "@hooks";
import { ReactNode } from "react";
import { RouteProps as ReactRouterRouteProps } from "react-router-dom";
import { HomeSidebarItem } from "./HomeSidebarItem";
import { AdminIcon, FilesIcon, HelpIcon, TemplatesIcon, UpdatesIcon } from "./icons";

const icons: Record<string, JSX.Element> = {
  projects: <FilesIcon />,
  templates: <TemplatesIcon />,
  updates: <UpdatesIcon />,
  help: <HelpIcon />,
  admin: <AdminIcon />,
};

export type RouteProps = ReactRouterRouteProps & { key: string };

type SidebarGroupProps = {
  header?: ReactNode;
  routes?: RouteProps[];
  activeRoute?: RouteProps;
};

function SidebarGroup({ header, routes = [], activeRoute }: SidebarGroupProps) {
  const { t } = useTranslation("home.sidebar");

  return (
    <VStack w="full" gap={0}>
      {header}
      {routes.map((route) => (
        <HomeSidebarItem
          key={route.key}
          icon={icons[route.key]}
          label={t(route.key)}
          path={route.path ?? ""}
          selected={activeRoute == route}
        />
      ))}
    </VStack>
  );
}

type HomeSidebarProps = {
  routes: {
    global: RouteProps[];
    organizations: { organization: Organization; sidebarRoutes: RouteProps[] }[];
  };
  activeRoute?: RouteProps;
};

//https://www.figma.com/file/0gXtyY1FbJM49ZvaF8zxGb/Design-system?type=design&node-id=1996-25254&mode=design&t=HTn7MABeglLtpXGW-4
export function HomeSidebar({ activeRoute, routes: { global, organizations } }: HomeSidebarProps) {
  const { border } = useThemeTokens();

  return (
    <Container
      as={VStack}
      flexFlow="column"
      borderColor={border.color}
      borderRightWidth={border.width}
      w="16.25rem"
      height="100vh"
      variant="classic"
    >
      <SidebarGroup activeRoute={activeRoute} header={<UserProfile />} />
      <Box gap={spacing.space["400"]}>
        {organizations.map(({ organization, sidebarRoutes }) => (
          <SidebarGroup
            key={organization.id}
            activeRoute={activeRoute}
            header={<OrganizationProfile organization={organization} />}
            routes={sidebarRoutes}
          />
        ))}
      </Box>
      <Spacer />
      <SidebarGroup activeRoute={activeRoute} routes={global} />
    </Container>
  );
}

function UserProfile() {
  const { user } = useAuth();

  return (
    <HStack
      gap={spacing.space["300"]}
      w="full"
      px={spacing.space["600"]}
      py={spacing.space["300"]}
      justifyContent="space-between"
    >
      <ApplicationContextMenu showThemeButtons={true} showLogout={true} />
      <Avatar name={user?.name} src={user?.avatar} size="xs" data-test-id="user-profile-avatar" />
    </HStack>
  );
}

type OrganizationProfileProps = { organization: Organization };

function OrganizationProfile({ organization }: OrganizationProfileProps) {
  const {
    text: { secondary },
  } = useSemanticTokens();
  return (
    <HStack
      gap={spacing.space["300"]}
      w="full"
      px={spacing.space["600"]}
      pt={spacing.space["400"]}
      pb={spacing.space["100"]}
      justifyContent="space-between"
    >
      <Text variant="label" color={secondary}>
        {organization.name}
      </Text>
      <Box w={spacing.space["600"]} h={spacing.space["600"]} />
    </HStack>
  );
}
