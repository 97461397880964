import { KnitStructuresState, ModelsStore } from "../types";
import { SetState } from "zustand";
import { KnitStructure } from "@models/backend";

export const knitStructuresSlice = (set: SetState<ModelsStore>): KnitStructuresState => ({
  knitStructures: [],

  setKnitStructures: (structures: KnitStructure[]) => {
    set({ knitStructures: structures });
  },
  clearKnitStructures: () => {
    set({ knitStructures: [] });
  },
});
