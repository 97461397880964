import { ColumnAnchor, Model, PathState, SectionAnchor } from "@models/project";
import { generateAutoColumnCurves, generateAutoSectionCurves } from "@utils/project/part";
import { useTranslation } from "@hooks";
import { useToast } from "@design-system";
import { useBackendColumnAnchor, useBackendSectionAnchor } from "@hooks";
import { Project, SectionAnchorAttributes, ColumnAnchorAttributes } from "@models/backend";
import { useModelsStore } from "@state/models";

type useCalibrationCurvesProps = {
  project: Project;
  model: Model;
};

export const useCalibrationCurves = ({ project, model }: useCalibrationCurvesProps) => {
  const toast = useToast();
  const { t } = useTranslation("hooks.project.calibration");
  const {
    addSectionAnchor: addSectionAnchorSlice,
    removeSectionAnchor: removeSectionAnchorSlice,
    addColumnAnchor: addColumnAnchorSlice,
    removeColumnAnchor: removeColumnAnchorSlice,
  } = useModelsStore();
  const { createBackendSectionAnchor, updateBackendSectionAnchors, deleteBackendSectionAnchor } =
    useBackendSectionAnchor();
  const { createBackendColumnAnchor, updateBackendColumnAnchors, deleteBackendColumnAnchor } = useBackendColumnAnchor();

  const addSectionAnchor = async (
    point: [number, number, number],
    curve: Float32Array,
    index: number,
    attributes?: SectionAnchorAttributes,
  ) => {
    const sectionAnchor: SectionAnchor = {
      idx: index,
      point,
      name: `Section ${index + 1}`,
      attributes: attributes || {},
      curve,
    };
    console.log("point", point);
    const result = await createBackendSectionAnchor(project.id, model.id, sectionAnchor);
    sectionAnchor.id = result.id;
    addSectionAnchorSlice(model.id, sectionAnchor);
    return sectionAnchor;
  };

  const addSectionAnchors = async (anchors: Array<Pick<SectionAnchor, "point" | "curve" | "idx" | "id">>) => {
    const nameAdded = anchors.map((anchor) => ({
      ...anchor,
      name: `Section ${anchor.idx + 1}`,
      attributes: {},
    }));
    const result = await updateBackendSectionAnchors(project.id, model.id, nameAdded);

    nameAdded.map((anchor) => {
      anchor.id = result[anchor.idx].id;
      addSectionAnchorSlice(model.id, anchor);
    });

    return nameAdded;
  };

  const removeSectionAnchor = async (sectionAnchorId: string) => {
    await deleteBackendSectionAnchor(project.id, model.id, sectionAnchorId);
    removeSectionAnchorSlice(model.id, sectionAnchorId);
  };

  const getAutoSections = async (
    guideSource: PathState | null,
    sectionsCount: number,
    existingSectionAnchors: SectionAnchor[],
  ) => {
    const points = guideSource?.points ?? [];

    if (!guideSource || points.length < 2) {
      return [];
    }

    const existingAutoSections = existingSectionAnchors
      // uncomment to preserve user curves
      // .filter(({ attributes }) => attributes.source === "auto")
      .map(({ id }) => id);

    // wipe existing sections
    if (existingAutoSections) {
      existingAutoSections.map(async (id) => {
        removeSectionAnchorSlice(model.id, id!);
      });
    }

    if (sectionsCount < 1) {
      return [];
    }

    try {
      const curves = await generateAutoSectionCurves(model, guideSource.cpp, sectionsCount);
      if (curves) {
        const sectionAnchors: SectionAnchor[] = await addSectionAnchors(
          curves.map(([point, curve], idx) => ({ point, curve, idx })),
        );
        return sectionAnchors;
      }
    } catch (error) {
      console.error({ message: "Failed to get section curves", error });
      const message = <p dangerouslySetInnerHTML={{ __html: t("section-curves.error.message") }}></p>;
      toast({
        title: t("section-curves.error.title"),
        children: message,
        variant: "error",
        isCloseable: true,
        duration: 3000,
      });
    }

    return [];
  };

  /// COLUMN ANCHORS

  const addColumnAnchor = async (
    point: [number, number, number],
    curve: Float32Array,
    index: number,
    attributes?: ColumnAnchorAttributes,
  ) => {
    const columnAnchor: ColumnAnchor = {
      idx: index,
      point,
      name: `Column ${index + 1}`,
      attributes: attributes || {},
      curve,
    };
    const result = await createBackendColumnAnchor(project.id, model.id, columnAnchor);
    columnAnchor.id = result.id;
    console.log(columnAnchor);
    addColumnAnchorSlice(model.id, columnAnchor);
    return columnAnchor;
  };

  const addColumnAnchors = async (anchors: Array<Pick<SectionAnchor, "point" | "curve" | "idx" | "id">>) => {
    const nameAdded = anchors.map((anchor) => ({
      ...anchor,
      name: `Column ${anchor.idx + 1}`,
      attributes: {},
    }));
    const result = await updateBackendColumnAnchors(project.id, model.id, nameAdded);

    nameAdded.map((anchor) => {
      anchor.id = result[anchor.idx].id;
      addColumnAnchorSlice(model.id, anchor);
    });

    return nameAdded;
  };

  const removeColumnAnchor = async (columnAnchorId: string) => {
    await deleteBackendColumnAnchor(project.id, model.id, columnAnchorId);
    removeColumnAnchorSlice(model.id, columnAnchorId);
  };

  const getAutoColumns = async (
    guideSource: PathState | null,
    columnsCount: number,
    existingColumnAnchors: ColumnAnchor[],
  ) => {
    const points = guideSource?.points ?? [];

    if (!guideSource || points.length < 2) {
      return [];
    }

    const existingAutoColumns = existingColumnAnchors
      // uncomment to preserve user curves
      // .filter(({ attributes }) => attributes.source === "auto")
      .map(({ id }) => id);

    // wipe existing columns
    if (existingAutoColumns) {
      existingAutoColumns.map(async (id) => {
        removeColumnAnchorSlice(model.id, id!);
      });
    }

    if (columnsCount < 1) {
      return [];
    }

    try {
      const curves = await generateAutoColumnCurves(model, guideSource.cpp, columnsCount);
      if (curves) {
        const columnAnchors: ColumnAnchor[] = await addColumnAnchors(
          curves.map(([point, curve], idx) => ({ point, curve, idx })),
        );
        return columnAnchors;
      }
    } catch (error) {
      console.error({ message: "Failed to get column curves", error });
      const message = <p dangerouslySetInnerHTML={{ __html: t("column-curves.error.message") }}></p>;
      toast({
        title: t("column-curves.error.title"),
        children: message,
        variant: "error",
        isCloseable: true,
        duration: 3000,
      });
    }

    return [];
  };

  return {
    addColumnAnchor,
    addSectionAnchor,
    getAutoColumns,
    getAutoSections,
    removeColumnAnchor,
    removeSectionAnchor,
  };
};
