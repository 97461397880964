import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  Flex,
  HStack,
  Spacer,
} from "@chakra-ui/react";
import { useSemanticTokens, spacing, EditableInput } from "@design-system";
import { cloneElement, ReactElement, ReactNode } from "react";
import { useDrag } from "react-dnd";
import { useHotkeys } from "react-hotkeys-hook";

const IconSizeMedium = "16px";

export type LayerData = {
  id: string;
  group: string;
  isLoop?: boolean;
};

type SidebarLayerProps = {
  layerType?: "model" | "path" | "section" | "column" | "reference";
  name: string;
  icon: ReactElement;
  secondary?: boolean;
  children?: ReactNode;
  displacement?: number;
  // Drag N Drop relevant data.
  layerData?: LayerData;
  onSelect: () => void;
  onHover: () => void;
  onUnhover: () => void;
  selected: boolean;
  hovered: boolean;
  onChange?: (newValue: string) => void;
  isEditable: boolean;
  onDelete?: () => void;
  "data-test-id"?: string;
};

type LayerStyles = {
  [key in "model" | "path" | "section" | "column" | "reference"]: {
    icon: {
      defaultColor: string;
      selectedColor: string;
      secondaryColor: string;
    };
    text: {
      defaultColor: string;
      selectedColor: string;
      secondaryColor: string;
    };
  };
};

function useLayerStyles(): LayerStyles {
  const semanticTokens = useSemanticTokens();

  return {
    model: {
      icon: {
        defaultColor: semanticTokens.icons.primary,
        selectedColor: semanticTokens.icons.secondary,
        secondaryColor: semanticTokens.icons.accent.primary,
      },
      text: {
        defaultColor: semanticTokens.text.classic.primary,
        selectedColor: semanticTokens.text.classic.primary,
        secondaryColor: semanticTokens.text.classic.secondary,
      },
    },
    path: {
      icon: {
        defaultColor: semanticTokens.icons.primary,
        selectedColor: semanticTokens.icons.secondary,
        secondaryColor: semanticTokens.icons.accent.primary,
      },
      text: {
        defaultColor: semanticTokens.text.classic.primary,
        selectedColor: semanticTokens.text.classic.primary,
        secondaryColor: semanticTokens.text.classic.secondary,
      },
    },
    section: {
      icon: {
        defaultColor: semanticTokens.icons.primary,
        selectedColor: semanticTokens.icons.secondary,
        secondaryColor: semanticTokens.icons.accent.primary,
      },
      text: {
        defaultColor: semanticTokens.text.classic.primary,
        selectedColor: semanticTokens.text.classic.primary,
        secondaryColor: semanticTokens.text.classic.secondary,
      },
    },
    column: {
      icon: {
        defaultColor: semanticTokens.icons.primary,
        selectedColor: semanticTokens.icons.secondary,
        secondaryColor: semanticTokens.icons.accent.primary,
      },
      text: {
        defaultColor: semanticTokens.text.classic.primary,
        selectedColor: semanticTokens.text.classic.primary,
        secondaryColor: semanticTokens.text.classic.secondary,
      },
    },
    reference: {
      icon: {
        defaultColor: semanticTokens.icons.primary,
        selectedColor: semanticTokens.icons.secondary,
        secondaryColor: semanticTokens.icons.accent.primary,
      },
      text: {
        defaultColor: semanticTokens.text.classic.primary,
        selectedColor: semanticTokens.text.classic.primary,
        secondaryColor: semanticTokens.text.classic.secondary,
      },
    },
  };
}

export function SidebarLayer({
  name,
  layerType = "model",
  icon,
  children,
  displacement = 0,
  layerData,
  secondary,
  selected,
  hovered,
  onSelect,
  onHover,
  onUnhover,
  onChange,
  isEditable = true,
  onDelete,
  ...rest
}: SidebarLayerProps) {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: layerType,
      item: { layerData, type: layerType },
      layerData,
      canDrag: () => layerType === "path",
      collect: (monitor) => ({ isDragging: !!monitor.isDragging() }),
    }),
    [layerData, layerType],
  );
  const layerStyles = useLayerStyles();
  const {
    icon: { defaultColor, selectedColor },
    text: { defaultColor: textDefaultColor, selectedColor: textSelectedColor, secondaryColor: textSecondaryColor },
  } = layerStyles[layerType];
  const semanticTokens = useSemanticTokens();

  icon = cloneElement(icon, {
    className: "layerIcon",
    boxSize: IconSizeMedium,
    color: selected ? selectedColor : defaultColor,
  });

  useHotkeys(["delete", "backspace"], () => {
    if (selected && onDelete) {
      onDelete();
    }
  });

  let containerVariant = hovered ? "sidebarLayerHovered" : "sidebarLayer";

  if (selected) {
    containerVariant = "sidebarLayerSelected";
  }

  return (
    <Accordion
      ref={drag}
      opacity={isDragging ? 0.5 : 1}
      allowMultiple
      defaultIndex={[0]}
      w="full"
      data-test-id={rest["data-test-id"]}
    >
      <AccordionItem w="full">
        <Container
          alignItems="center"
          gap={spacing.space[50]}
          variant={containerVariant}
          height="1.5rem"
          _hover={
            selected
              ? {}
              : {
                  bg: semanticTokens.surface.classic.secondary,
                  ".row-hover": { visibility: "visible" },
                }
          }
          onClick={onSelect}
          onPointerEnter={onHover}
          onPointerLeave={onUnhover}
        >
          <Flex alignItems="center" height="100%">
            {displacement ? <Spacer mx={`${0.5 * displacement}rem`} /> : null}
            {children ? (
              <AccordionButton
                onClick={(e) => {
                  e.stopPropagation();
                }}
                userSelect="none"
              >
                <AccordionIcon color={selected ? semanticTokens.icons.invert : semanticTokens.icons.primary} />
              </AccordionButton>
            ) : (
              <Spacer mx="0.5rem" />
            )}
            {icon}
          </Flex>
          <EditableInput
            initialValue={name}
            textColor={selected ? textSelectedColor : secondary ? textSecondaryColor : textDefaultColor}
            textVariant="2xs-regular"
            onChange={onChange}
            isEditable={isEditable}
          />
        </Container>
        {children && (
          <HStack role="group" gap={0}>
            <Box w="full">
              <AccordionPanel w="full" rowGap={0} marginTop={0}>
                {children}
              </AccordionPanel>
            </Box>
          </HStack>
        )}
      </AccordionItem>
    </Accordion>
  );
}
