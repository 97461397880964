import { Model } from "@models/project";
import { useMemo, useState, useEffect } from "react";
import { BufferGeometry, MeshPhysicalMaterial } from "three";
import { getZoneGeo } from "@utils/project/zone";
import { useZone } from "@hooks/project";
import { useModelsStore } from "@state/models";
import { workspace3dTokens } from "./workspace-3d-tokens";
import { colors } from "@design-system/colors";

export interface ZoneProps {
  model: Model;
  pathCollectionId: string;
  index: number;
}

export function Zone({ model, index, pathCollectionId }: ZoneProps) {
  const { mesh3DBase } = useZone(model, pathCollectionId);
  const [bufferGeometry, setBufferGeometry] = useState<BufferGeometry | undefined>(undefined);
  const knitStructures = useModelsStore((state) => state.knitStructures);
  const pathCollection = useModelsStore(
    ({ pathCollections }) => pathCollections?.[model.id]?.collections[pathCollectionId],
  );
  const selectedKnitStructure = useMemo(() => {
    const modelKnitStructure = model.attributes?.knitStructure;
    const pathCollectionKnitStructure = pathCollection?.attributes?.knitStructure;
    const defaultKnitStructure = knitStructures.find(({ isDefault }) => isDefault);
    return pathCollectionKnitStructure ?? modelKnitStructure ?? defaultKnitStructure;
  }, [model, pathCollection, knitStructures]);

  useEffect(() => {
    const getAndSetGeo = async () => {
      if (mesh3DBase) {
        const geo = await getZoneGeo(mesh3DBase);
        setBufferGeometry(geo);
      }
    };
    getAndSetGeo();
  }, [mesh3DBase, model.attributes?.normals?.flipNormals]);

  const zoneMat = useMemo(() => {
    return new MeshPhysicalMaterial({
      color: selectedKnitStructure?.color ?? colors.gray["200"],
      polygonOffset: true,
      polygonOffsetUnits: 1,
      polygonOffsetFactor: -1 - index,
      ...workspace3dTokens.material,
    });
  }, [bufferGeometry, selectedKnitStructure, index]);

  const zoneMesh = useMemo(() => {
    return <mesh geometry={bufferGeometry} material={zoneMat} renderOrder={index} />;
  }, [bufferGeometry, index, model.attributes?.normals?.flipNormals, zoneMat]);

  return bufferGeometry ? zoneMesh : null;
}
